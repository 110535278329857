import React, { useEffect, useState, useCallback } from 'react'
import {
    CommandBar,
    PrimaryButton,
    Text,
    Icon,
    Stack,
    MessageBar,
    MessageBarType,
    Modal,
    DefaultButton,
    DetailsList,
    SelectionMode,
} from '@fluentui/react'
import Template from '../components/template/Template'

import { triggerWorkflowAndParams } from '../api'

import Page from '../components/layout/Page'
import LoadingSpinner from '../components/LoadingSpinner'
import { useParams } from 'react-router-dom'
import useTemplate from '../hooks/useTemplate'
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../hooks/usePermissions'

const TemplateView = () => {
    const params = useParams()
    const navigate = useNavigate()
    console.log('MATCH', params)
    const { templateName, entityId } = params

    const {
        template,
        error,
        ready,
        fields,
        actions,
        entityType,
        entity,
        isDirty,
        saveError,
        isCreate,
        readonly,
        handleSubmit,
    } = useTemplate(templateName, entityId)

    const [dataModalOpen, setDataModalOpen] = useState(false)

    const { hasPermissions } = usePermissions()

    const entityTypeName = entityType ? entityType.name : null
    // const hasViewPermission = hasPermissions([
    //     'getOne_entity',
    //     `getOne_entity:${entityTypeName}`,
    // ])
    // const hasCreatePermission = hasPermissions([
    //     'create_entity',
    //     `create_entity:${entityTypeName}`,
    // ])
    const hasEditPermission = hasPermissions([
        'update_entity',
        `update_entity:${entityTypeName}`,
    ])

    if (!template) {
        return (
            <Page>
                <LoadingSpinner />
            </Page>
        )
    }

    if (template && template.templateError) {
        return (
            <Page>
                <Error
                    error={{
                        title: 'Error loading template',
                        msg: template.templateError,
                    }}
                />
            </Page>
        )
    }

    if (error) {
        return (
            <Page>
                <Error error={error} />
            </Page>
        )
    }

    const onClickCreateAction = async (action) => {
        const transaction = await triggerWorkflowAndParams(
            action.workflow,
            entityType.name,
            entity._id,
        )

        navigate(`/transactions/${transaction._id}`)
    }

    const startTransactionSubMenuItems = actions.length
        ? {
              items: actions.map((action) => {
                  return {
                      iconProps: { iconName: 'Play' },
                      ...action,
                      onClick: (e) => {
                          e.preventDefault()
                          onClickCreateAction(action)
                          return false
                      },
                  }
              }),
          }
        : undefined

    const onClickCreate = () =>
        navigate(
            `/createtransaction?entity=${entity._id}&entityType=${entityType.name}`,
        )

    const onShowTransactions = () =>
        navigate(
            `/transactions?entity=${entity._id}&entityType=${entityType.name}`,
        )

    const onClickEdit = () => {
        const newUrl =
            window.location.pathname +
            window.location.search
                .replace('readonly=true&', '')
                .replace('readonly=true', '')
        navigate(newUrl)
    }

    const onClickView = () => {
        let newUrl = window.location.pathname
        if (window.location.search) {
            newUrl += window.location.search.replace('readonly=false', '')
        } else {
            newUrl += '?'
        }
        newUrl += '&readonly=true'
        newUrl = newUrl.replace(/\?&+/, '?')

        navigate(newUrl)
    }

    const onData = () => {
        setDataModalOpen(true)
    }

    console.log('SM DIRTY', isDirty, saveError)

    const commandBarButtons = [
        <CommandBarButton
            key='back'
            text='Back'
            iconProps={{ iconName: 'Back' }}
            onClick={() => navigate(-1)}
        />,
        isCreate ? null : (
            <CommandBarButton
                key='show-transactions'
                text='Show Transactions'
                iconProps={{ iconName: 'OpenSource' }}
                onClick={onShowTransactions}
            />
        ),
        isCreate ? null : (
            <CommandBarButton
                key='start-transaction'
                text='Start Transaction'
                iconProps={{ iconName: 'Play' }}
                onClick={onClickCreate}
                split={true}
                subMenuProps={startTransactionSubMenuItems}
            />
        ),
        readonly ? (
            hasEditPermission ? (
                <CommandBarButton
                    key='edit'
                    text='Edit'
                    iconProps={{ iconName: 'Edit' }}
                    onClick={onClickEdit}
                />
            ) : null
        ) : isCreate ? null : (
            <CommandBarButton
                key='view'
                text='View'
                iconProps={{ iconName: 'View' }}
                onClick={onClickView}
            />
        ),
        // Disable archive for now
        // <CommandBarButton
        //     key='archive'
        //     text='Archive'
        //     iconProps={{ iconName: 'Delete' }}
        // />,

        (ready && !readonly && (
            <CommandBarButton
                disabled={!isDirty || saveError}
                key='save'
                text={isCreate ? 'Save' : 'Update'}
                iconProps={{ iconName: 'Save' }}
                onClick={handleSubmit}
            />
        )) ||
            null,
        <CommandBarButton
            key='data'
            text='Data'
            iconProps={{ iconName: 'EntryView' }}
            onClick={onData}
        />,
    ].filter((a) => !!a)

    const actionBar = <MyCommandBar>{commandBarButtons}</MyCommandBar>

    console.log('Template render')
    // Lists don't need a submit button
    const hasButtons = template.content.contents[0].type !== 'LIST'
    return (
        <Page>
            <Text variant='xxLarge'>{template.title}</Text>
            {hasButtons ? actionBar : null}
            {saveError ? (
                <MessageBar messageBarType={MessageBarType.error}>
                    {saveError}
                </MessageBar>
            ) : null}
            {ready ? (
                <Template
                    checkPermissions
                    template={template}
                    contents={template.content.contents}
                    fields={fields}
                    root={true}
                    entity={entity}
                    readonly={readonly}
                />
            ) : (
                <p>Loading...</p>
            )}
            <EntityModal
                isOpen={dataModalOpen}
                onDismiss={() => setDataModalOpen(false)}
                entity={entity}
            />
        </Page>
    )
}

/**
 *
 * ==================================================
 * ==================================================
 * ==================================================
 *
 */

const Error = ({ error }) => {
    return (
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 20 }}>
            <Icon
                iconName={error.icon ? error.icon : 'Error'}
                style={{
                    width: 75,
                    height: 75,
                    fontSize: 75,
                    lineHeight: '1em',
                    color: 'red',
                }}
            />
            <Stack>
                <Text variant='xxLarge'>{error.title}</Text>
                <Text variant='large'>{error.msg}</Text>
            </Stack>
        </Stack>
    )
}

const MyCommandBar = ({ children }) => {
    const items = (Array.isArray(children) ? children : [children])
        .filter((child) => child.type.name === CommandBarButton.name)
        .map((child, i) => {
            return {
                key: i,
                ...child.props,
            }
        })
    return <CommandBar items={items} />
}

const CommandBarButton = () => null

const EntityModal = ({ entity, isOpen, onDismiss }) => {
    const [view, setView] = useState('data')
    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            styles={{ main: { padding: 20 } }}>
            <Stack tokens={{ childrenGap: 20 }}>
                <Stack tokens={{ childrenGap: 20 }} horizontal>
                    <DefaultButton onClick={() => setView('data')}>
                        Data
                    </DefaultButton>
                    <DefaultButton onClick={() => setView('raw')}>
                        Raw
                    </DefaultButton>
                </Stack>

                {view === 'data' ? <DataBlock>{entity}</DataBlock> : null}
                {view === 'raw' ? <CodeBlock>{entity}</CodeBlock> : null}
            </Stack>
        </Modal>
    )
}

const CodeBlock = ({ children }) => {
    const style = {
        whiteSpace: 'pre',
        background: '#eee',
        display: 'block',
        border: '1px solid #d4d4d4',
        borderRadius: 4,
        padding: 10,
        overflow: 'scroll',
        maxHeight: 500,
        fontSize: 'smaller',
    }
    return (
        <div>
            <code style={style}>{JSON.stringify(children, null, 4)}</code>
        </div>
    )
}

const DataBlock = ({ children }) => {
    const items = children.data
    console.log(children)
    const columns = [
        {
            key: 'field',
            fieldName: 'field',
            name: 'Field',
            minWidth: 100,
        },
        {
            key: 'values',
            fieldName: 'values',
            name: 'Value',
            minWidth: 300,
        },
        {
            key: 'type',
            fieldName: 'type',
            name: 'Type',
            minWidth: 100,
        },
    ]

    return (
        <div>
            <DetailsList
                items={items}
                columns={columns}
                selectionMode={SelectionMode.none}
            />
        </div>
    )
}

export default TemplateView
