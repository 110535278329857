import React from 'react'

import * as api from '../api'

const AuthContext = React.createContext()

const defaultAuthState = {
    loggedIn: false,
    refreshToken: null,
    accessToken: null,
    userId: null,
    email: null,
    firstName: null,
    lastName: null,
    loadedAccounts: false,
    accountGroups: [],
}

const initialState = api.loadAuthState(defaultAuthState)

console.log('initialState', initialState)

const authReducer = (state, action) => {
    switch (action.type) {
        case 'login': {
            let currentAccount = null
            if (action.accountGroups && action.accountGroups.length) {
                currentAccount = action.accountGroups[0]
            }
            return {
                ...state,
                userId: action._id,
                email: action.email,
                refreshToken: action.refreshToken,
                accessToken: action.accessToken,
                firstName: action.firstName,
                lastName: action.lastName,
                roles: action.roles,
                loggedIn: true,
                accountGroups: action.accountGroups,
                currentAccount,
            }
        }
        case 'logout': {
            return {
                ...state,
                ...defaultAuthState,
            }
        }
        case 'setCurrentAccount': {
            let currentAccount = null
            if (action.currentAccountId) {
                currentAccount = state.accountGroups.find(
                    ({ account }) => account._id === action.currentAccountId,
                )
            }
            return {
                ...state,
                currentAccount,
            }
        }
        default: {
            return state
        }
    }
}

function AuthProvider(props) {
    const [state, dispatch] = React.useReducer(authReducer, initialState)
    const [userSwitch, setUserSwitch] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const {
        loggedIn,
        userId,
        email,
        firstName,
        lastName,
        refreshToken,
        accessToken,
        roles,
        currentAccount,
        accountGroups,
    } = state

    // console.log('Auth state', state)

    const login = (email, password) => {
        return api
            .login(email, password)
            .then((data) => {
                dispatch({ type: 'login', ...data })
            })
            .then(() => {
                console.log('GGG')
            })
    }

    const loginSSO = (data) => {
        dispatch({ type: 'login', ...data })
    }

    const setCurrentAccount = (currentAccountId) => {
        dispatch({ type: 'setCurrentAccount', currentAccountId })

        // Reload the page so effect is seen
        window.location.reload()
    }

    React.useEffect(() => {
        const currentAccountId = currentAccount
            ? currentAccount.account._id
            : null

        api.saveAuthState(state)
        api.setAuthTokens(refreshToken, accessToken, currentAccountId)

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [state, setUserSwitch])

    const onRouteChange = React.useCallback(() => {
        // Do we have an encoded payload?
        const params = new URLSearchParams(window.location.search)
        if (params.has('data')) {
            const data = params.get('data')
        }

        // Detect if the url has account or user params
        const hasUserParam = params.has('user')
        const hasAccountParam = params.has('account')
        console.log(
            'currentAccount',
            loading,
            currentAccount,
            state,
            params,
            '' + window.location,
            userSwitch,
            hasUserParam,
        )

        let needToReplaceState = false
        if (hasUserParam) {
            // Validation
            const userId = params.get('user')
            const displayName = params.get('displayName')
            const email = params.get('email')
            console.log('userId', params, userId, state)

            if (state.userId !== userId) {
                // Need to switch users
                setUserSwitch({
                    type: 'user',
                    userId: userId,
                    displayName,
                    email,
                })
            } else {
                console.log('clearing user params', params)
                // We are already logged in as this user, clear the search params
                params.delete('user')
                params.delete('displayName')
                params.delete('email')
                console.log('clearing user params', params)

                needToReplaceState = true
            }
        }

        const currentAccountId = currentAccount
            ? currentAccount.account._id
            : null

        let updateParams = false
        if (hasAccountParam) {
            const accountId = params.get('account')
            const accountName = params.get('accountName')

            const accountIds = new Set(
                state.accountGroups.map(({ account }) => account._id),
            )

            console.log('hasAccountParam', accountId, accountName, accountIds)
            if (accountId !== currentAccountId) {
                // Check to see if this user has access to this account at all
                if (accountIds.has(accountId)) {
                    // They do, we can auto switch account
                    // currentAccountId = accountId
                    setCurrentAccount(accountId)
                    updateParams = true
                    console.log('Setting current account', accountId)
                    // return
                } else {
                    // We don't have access to that account, prompt to login as another user
                    setUserSwitch({
                        type: 'account',
                        accountId,
                        accountName,
                    })
                }
            } else {
                updateParams = true
            }
        }

        if (updateParams) {
            console.log('updating account params')
            params.delete('account')
            params.delete('accountName')

            needToReplaceState = true
        }

        // console.log('set timer')
        const timer = setTimeout(() => {
            // console.log('timer', needToReplaceState)
            if (needToReplaceState) {
                const newUrl =
                    window.location.pathname + '?' + params.toString()
                window.history.replaceState({}, null, newUrl)
            }
        }, 500)

        return () => {
            console.log('clear timer')
            clearTimeout(timer)
        }
    }, [setUserSwitch, setCurrentAccount])

    const signup = (data) => {
        return api.signup(data).then((data) => {
            dispatch({ type: 'login', ...data })
        })
    }
    const logout = () => {
        dispatch({ type: 'logout' })
    }

    const refreshAuth = async () => {
        const tokens = await api.refreshTokens().catch((err) => {
            console.log('Error refreshing tokens', err)
        })

        api.setAuthTokens(
            tokens.refreshToken,
            tokens.accessToken,
            currentAccount ? currentAccount.account._id : null,
        )
        return tokens
    }

    return (
        <AuthContext.Provider
            value={{
                loggedIn,
                userId,
                email,
                firstName,
                lastName,
                login,
                loginSSO,
                logout,
                signup,
                loading,
                accessToken,
                refreshAuth,
                roles,
                currentAccount,
                setCurrentAccount,
                accountGroups,
                userSwitch,
                onRouteChange,
            }}
            {...props}
        />
    )
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
