import React from 'react'

import { getTheme } from '@fluentui/style-utilities'
const defaultLabels = {
    not_started: 'Not Started',
    in_progress: 'In Progress',
    complete: 'Complete',
    unknown: 'Unknown',
    aborted: 'Aborted',
}

const badgeStyle = {
    color: 'white',
    background: 'light-gray',
    padding: '4px 10px',
    borderRadius: '12px',
    fontWeight: 'bolder',
}

const theme = getTheme()

const defaultBadgeStyles = {
    not_started: {
        background: theme.palette.yellow,
    },
    in_progress: {
        background: theme.palette.blue,
    },
    complete: {
        background: theme.palette.green,
    },
    aborted: {
        background: theme.palette.orange,
    },
}

const StatusBadge = ({
    status = 'unknown',
    badgeStyles = defaultBadgeStyles,
    labels = defaultLabels,
}) => {
    const label = labels[status] || 'unknown status'
    const style = Object.assign({}, badgeStyle, badgeStyles[status] || {})
    return <span style={style}>{label}</span>
}

export default StatusBadge
