import React from 'react'

import {
    Modal,
    Stack,
    IconButton,
    PrimaryButton,
    DefaultButton,
    MessageBar,
    MessageBarType,
} from '@fluentui/react'

import { useAuth } from '@/hooks/useAuth'
import { abortTransaction } from '../../api'

const AbortModal = ({
    isOpen,
    onClose = () => {},
    loadData = () => {},
    onAbort = () => {},
    transaction = null,
}) => {
    const title = 'Abort Transaction'

    const onClickAbortTransaction = () => {
        abortTransaction(transaction._id)
            .then(() => {
                onClose()
                loadData()
            })
            .catch((err) => {
                console.log('Error aborting transaction', err)
            })
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        // styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>
                <p>
                    Are you sure you want to abort transaction{' '}
                    <b>{transaction ? transaction.title : ''}</b>?
                </p>
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                    This action cannot be undone.
                </MessageBar>
                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onClickAbortTransaction}>
                        I am sure
                    </PrimaryButton>
                    <DefaultButton onClick={onClose}>Close</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AbortModal
