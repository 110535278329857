import React from 'react'

import {
    Text,
    CommandBar,
    Stack,
    Toggle,
    Selection,
    IconButton,
} from '@fluentui/react'

import { useNavigate } from 'react-router-dom'

import Page from '../../components/layout/Page'
import TransactionList from '../../components/TransactionList'

import { useSearchParams } from '../../hooks/useSearchParams'
import Filters from '../../components/template/Filters'
import { useAuth } from '../../hooks/useAuth'
import AbortModal from './AbortModal'

const TransactionListView = () => {
    const navigate = useNavigate()
    const { userId } = useAuth()
    const { entity = null, entityType = null } = useSearchParams()
    const [showCompleted, setShowCompleted] = React.useState(true)
    const [showAborted, setShowAborted] = React.useState(false)
    const [search, setSearch] = React.useState(null)
    const [selectedItems, setSelectedItems] = React.useState(null)
    const [isAbortModalOpen, setIsAbortModalOpen] = React.useState(false)

    const [selection] = React.useState(
        new Selection({
            onSelectionChanged: (...args) => {
                setSelectedItems(selection.getSelection())
            },
        }),
    )

    const handleShowCompleted = React.useCallback(() => {
        setShowCompleted((showCompleted) => !showCompleted)
    }, [setShowCompleted])
    const handleShowAborted = React.useCallback(() => {
        setShowAborted((showAborted) => !showAborted)
    }, [setShowAborted])

    const onSearch = React.useCallback(
        (value) => {
            setSearch(value)
        },
        [setSearch],
    )

    const loadData = () => {
        window.location.reload()
    }

    const hasSelection = !!(selectedItems && selectedItems.length)
    const selectedItem = hasSelection ? selectedItems[0] : null
    const selectionCreatedByUser =
        hasSelection && selectedItem.createdBy._id === userId

    console.log(
        userId,
        selectedItem ? selectedItem.createdBy._id : null,
        selectionCreatedByUser,
    )

    const actions = [
        {
            key: 'create',
            text: 'Create Transaction',
            onClick: () => {
                navigate('/createtransaction')
            },
            iconProps: { iconName: 'Add' },
        },
        {
            key: 'view',
            text: 'View Transaction',
            onClick: () => {
                navigate(`/transactions/${selectedItem._id}`)
            },
            iconProps: { iconName: 'PreviewLink' },
            disabled: !hasSelection,
        },
        {
            key: 'abort',
            text: 'Abort',
            onClick: () => {
                setIsAbortModalOpen(true)
            },
            iconProps: { iconName: 'Cancel' },
            disabled: !hasSelection || !selectionCreatedByUser,
        },
    ].filter((a) => !!a)

    const menuProps = {
        items: [
            {
                key: 'completed',
                text: 'Show Completed',
                onRender() {
                    return (
                        <Toggle
                            inlineLabel
                            label='Show completed'
                            checked={showCompleted}
                            onChange={handleShowCompleted}
                            styles={{ root: { padding: '0.5em 1em' } }}
                        />
                    )
                },
            },
            {
                key: 'aborted',
                text: 'Show aborted',
                onRender() {
                    return (
                        <Toggle
                            inlineLabel
                            label='Show aborted'
                            checked={showAborted}
                            onChange={handleShowAborted}
                            styles={{ root: { padding: '0.5em 1em' } }}
                        />
                    )
                },
            },
        ],
    }

    return (
        <Page>
            <Text variant='xxLarge' block={true}>
                Transactions
            </Text>
            <Stack horizontal tokens={{ childrenGap: 30 }}>
                <Stack.Item grow='1'>
                    <CommandBar items={actions} />
                </Stack.Item>
                <Stack.Item>
                    <IconButton
                        iconProps={{ iconName: 'View' }}
                        menuProps={menuProps}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Filters onChange={onSearch} value={search} />
                </Stack.Item>
            </Stack>
            <TransactionList
                showStatuses={{ showCompleted, showAborted }}
                selection={selection}
                entity={entity}
                entityType={entityType}
                search={search}
            />
            <AbortModal
                isOpen={isAbortModalOpen}
                onClose={() => setIsAbortModalOpen(false)}
                loadData={loadData}
                transaction={selectedItem}
            />
        </Page>
    )
}

export default TransactionListView
