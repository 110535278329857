import React from 'react'
import Page from '../components/layout/Page'
import { Text, Stack } from '@fluentui/react'
import { DonutChart } from '@fluentui/react-charting'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import TransactionList from '../components/TransactionList'
import { getTransactionsStats, getTransactionsWorkflowStats } from '../api'
import { DefaultPalette } from '@fluentui/react/lib/Styling'

const Box = ({ children, style }) => {
    return (
        <Stack
            style={{
                boxShadow: '5px 5px 10px rgba(0,0,0,0.1)',
                padding: 20,
                ...style,
            }}>
            {children}
        </Stack>
    )
}

const styles = {
    tokens: { childrenGap: 30 },
    title: { margin: 0 },
    text: { fontSize: '3em' },
    boxSmall: {
        width: 200,
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    },
    boxMedium: { height: 300, minWidth: 500, flexGrow: 1 },
    boxTransactions: { maxHeight: 600, overflowY: 'scroll' },
}

const colors = [
    DefaultPalette.blue,
    DefaultPalette.green,
    DefaultPalette.yellow,
    DefaultPalette.red,
    DefaultPalette.magenta,
    DefaultPalette.orange,
    DefaultPalette.purple,
    DefaultPalette.teal,
    DefaultPalette.blueDark,
    DefaultPalette.greenDark,
]

const Dashboard = (props) => {
    const { firstName } = useAuth()

    const [stats, setStats] = React.useState({})
    const [workflowStats, setWorkflowStats] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const getData = () => {
        Promise.all([
            getTransactionsStats(),
            getTransactionsWorkflowStats(),
        ]).then(([stats, workflowStats]) => {
            setWorkflowStats(workflowStats)
            setStats(stats)
            setLoading(false)
        })
    }

    React.useEffect(() => {
        getData()
    }, [])

    const data = {
        chartTitle: 'Transcation Status',
        chartData: loading
            ? []
            : [
                  {
                      legend: 'In Progress',
                      data: stats.in_progress,
                      color: DefaultPalette.blue,
                  },
                  {
                      legend: 'Complete',
                      data: stats.complete,
                      color: DefaultPalette.green,
                  },
                  {
                      legend: 'Not Started',
                      data: stats.not_started,
                      color: DefaultPalette.yellow,
                  },
              ],
    }

    const dataWorkflow = {
        chartTitle: 'Workflow Stats',
        chartData: loading
            ? []
            : workflowStats.map((workflowStat, i) => {
                  return {
                      legend: workflowStat.title,
                      data: workflowStat.count,
                      color: colors[i],
                  }
              }),
    }

    return (
        <Page>
            <Text variant='xxLarge'>Welcome {firstName}</Text>
            <Stack tokens={styles.tokens}>
                <Stack tokens={styles.tokens} horizontal>
                    <Stack tokens={styles.tokens}>
                        <Box style={styles.boxSmall}>
                            <h3 style={styles.title}>In Progress</h3>
                            <span style={styles.text}>
                                {stats.in_progress ? stats.in_progress : '-'}
                            </span>
                        </Box>

                        <Box style={styles.boxSmall}>
                            <h3 style={styles.title}>Complete</h3>
                            <span style={styles.text}>
                                {stats.complete ? stats.complete : '-'}
                            </span>
                        </Box>
                    </Stack>
                    <Stack
                        tokens={styles.tokens}
                        horizontal
                        style={{ flexGrow: 1 }}>
                        <Box style={styles.boxMedium}>
                            <h3 style={styles.title}>Status</h3>
                            <DonutChart
                                data={data}
                                innerRadius={55}
                                legendProps={{
                                    allowFocusOnLegends: true,
                                }}
                            />
                        </Box>
                        <Box style={styles.boxMedium}>
                            <h3 style={styles.title}>Workflow</h3>
                            <DonutChart
                                data={dataWorkflow}
                                innerRadius={55}
                                legendProps={{
                                    allowFocusOnLegends: true,
                                }}
                            />
                        </Box>
                    </Stack>
                </Stack>
                <Stack tokens={styles.tokens}>
                    <Box style={styles.boxTransactions}>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 10 }}
                            verticalAlign='center'>
                            <h3 style={styles.title}>Latest Transactions</h3>
                            <Link to='/transactions'>
                                <Text variant='small'>View all</Text>
                            </Link>
                        </Stack>
                        <TransactionList
                            limit={12}
                            showStatuses={{
                                showCompleted: false,
                                showAborted: false,
                            }}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Page>
    )
}

export default Dashboard
